import { template as template_069b76e91d884473bdecca29a3a286ae } from "@ember/template-compiler";
const FKText = template_069b76e91d884473bdecca29a3a286ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
