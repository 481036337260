import { template as template_1b7fc4ac5f13410b897f7bfad04fe787 } from "@ember/template-compiler";
const FKLabel = template_1b7fc4ac5f13410b897f7bfad04fe787(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
